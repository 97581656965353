import {
  getDay,
  getDiffInHours,
  getFormattedFullDate,
  getFormattedShortStartDate,
  getFormattedStartDate,
  getFormattedStartTime,
  getMomentDate,
  getMonth,
  getUtcOffset,
  getWeekDay,
  now,
} from '@wix/events-moment-commons'
import {EventsDates, EventDateInformation} from '../types/state'

const getDaysLeft = (event: wix.events.Event) => {
  const {timeZoneId, startDate} = event.scheduling.config
  const nowMoment = now(timeZoneId)
  const start = getMomentDate(startDate, timeZoneId)
  const hours = Math.floor(getDiffInHours(start, nowMoment))
  const days = Math.ceil((hours - start.hour()) / 24)

  return days < 0 ? 0 : days
}

export const eventsToDates = (eventsList: wix.events.Event[], fullLocale: string) => {
  return eventsList
    .filter((event) => event)
    .reduce((state, event) => {
      const {startDate, timeZoneId} = event.scheduling.config
      const information: EventDateInformation = {
        utcOffset: getUtcOffset(startDate, timeZoneId),
        monthDay: getDay(startDate, timeZoneId, fullLocale),
        weekDay: getWeekDay(startDate, timeZoneId, fullLocale),
        month: getMonth(startDate, timeZoneId, fullLocale),
        fullDate: getFormattedFullDate(event, fullLocale),
        shortStartDate: getFormattedShortStartDate(event, fullLocale),
        startDate: getFormattedStartDate(event, fullLocale),
        startTime: getFormattedStartTime(event, fullLocale),
        daysLeft: getDaysLeft(event),
        day: getDay(startDate, timeZoneId, fullLocale),
      }
      state[event.id] = information

      return state
    }, {} as EventsDates)
}
